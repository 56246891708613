import React from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { getImage, StaticImage } from 'gatsby-plugin-image';
import { FacebookShareButton } from 'react-share';

import {
    grid,
    imagesContainer,
    titleContainer,
    categoryContainer,
    miscContainer,
    backgroundImage as backgroundImageClass,
    mainImage as mainImageClass,
    mainImageRatio,
    categoryPosition,
    title as titleClass,
    titleFirstLine,
    titleRest,
    description as descriptionClass,
    summary,
    buttonsContainer,
    buttonIcon,
    proposition,
} from './recipe-meta.module.scss';

import { IRecipe } from '../../models/recipe.model';

import prependStrapiUrl from '../../utils/prepend-strapi-url';

import RecipeSummary from '../atoms/recipe-summary';
import Button from '../atoms/button';
import RatioImage from '../atoms/ratio-image';
import CategoryLabel from '../atoms/category-label';
import DownloadIcon from '../../assets/images/svg/download-02.svg';
import ShareIcon from '../../assets/images/svg/share.svg';

const backgroundSrc = '../../assets/images/recipe-background.png';

interface IRecipeMeta {
    recipe: IRecipe;
}

const RecipeMeta: React.FC<IRecipeMeta> = ({ recipe }) => {
    const {
        slug,
        title,
        description,
        backgroundImage,
        mainImage,
        recipeSummary,
        recipePdf,
        recipeCategories,
    } = recipe;
    const recipeCategory = recipeCategories?.filter((category) => !category.isRoot)?.[0];
    const { t } = useI18next();
    const words = title.trim().split(' ');
    const titleTransformed = {
        firstLine: words.shift(),
        rest: words.join(' '),
    };

    return (
        <div className={grid}>
            <div className={imagesContainer}>
                <StaticImage
                    className={backgroundImageClass}
                    src={backgroundSrc}
                    alt={'Tło przepisu'}
                />
                <RatioImage
                    image={backgroundImage?.localFile && getImage(backgroundImage.localFile)}
                    ratioClass={mainImageRatio}
                    className={mainImageClass}
                    alt={backgroundImage?.alternativeText}
                />
                <RatioImage
                    image={mainImage?.localFile && getImage(mainImage.localFile)}
                    ratioClass={mainImageRatio}
                    className={mainImageClass}
                    alt={mainImage?.alternativeText}
                    objectFit="contain"
                />
                <p className={proposition}>{t('recipe.page.meta.proposition')}</p>
            </div>
            <div className={categoryContainer}>
                <div className={categoryPosition}>
                    <CategoryLabel category={recipeCategory} />
                </div>
            </div>
            <div className={titleContainer}>
                <h1 className={titleClass}>
                    <span className={titleFirstLine}>{titleTransformed.firstLine}</span>
                    <span className={titleRest}>{titleTransformed.rest}</span>
                </h1>
            </div>
            <div className={miscContainer}>
                <p className={descriptionClass}>{description}</p>
                <RecipeSummary className={summary} recipeSummary={recipeSummary} />
                <div className={buttonsContainer}>
                    {recipePdf && recipePdf.url.length > 0 && (
                        <Button
                            fullWidth={true}
                            variant={'outlined'}
                            as="externalLink"
                            to={prependStrapiUrl(recipePdf.url)}
                        >
                            <DownloadIcon className={buttonIcon} />
                            {t('recipe.page.meta.download')}
                        </Button>
                    )}
                    {!recipeCategories.find((category) => category.businessMeaning === 'caffe') && (
                        <FacebookShareButton url={`${pageUrl}/${slug}`} quote={title}>
                            <Button fullWidth={true} as="element">
                                <ShareIcon className={buttonIcon} />
                                {t('recipe.page.meta.share')}
                            </Button>
                        </FacebookShareButton>
                    )}
                </div>
            </div>
        </div>
    );
};

const pageUrl = process.env.BASE_URL;

export default RecipeMeta;
