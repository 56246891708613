import React from 'react';
import 'swiper/css';

import { noSlide } from './video-slider.module.scss';

import { IVideo } from '../../models/video.model';
import Title, { ITitleProps } from '../atoms/title';
import Slider from '../hoc/slider';
import VideoCard from './video-card';
import AngleButton from '../atoms/angle-button';

interface IVideoSlider {
    className?: string;
    data: IVideo[];
    title?: string;
    titleProps?: ITitleProps;
    videoCardType?: 'simple' | 'full';
}

const VideoSlider: React.FC<IVideoSlider> = ({
    className = '',
    data,
    title = '',
    titleProps = {},
}) => {
    return (
        <div className={className}>
            <Title {...titleProps}>{title}</Title>
            <Slider
                ButtonComponent={AngleButton}
                showNav="desktop"
                forceArrowSpace={true}
                noSliderItemClass={noSlide}
            >
                {data.map((video, index) => {
                    return <VideoCard data={video} key={`video-${index}`} />;
                })}
            </Slider>
        </div>
    );
};

export default VideoSlider;
