import React from 'react';
import { graphql } from 'gatsby';
import { useI18next } from '../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import {
    grid,
    contentWrapper,
    pageSection,
    vlogHeader,
    vlogSlider,
} from './recipe-page.module.scss';

import { IRecipe } from '../models/recipe.model';
import { IVideo } from '../models/video.model';
import { IQueryAllResult } from '../models/query-all-result.model';
import { IMeta } from '../models/meta.model';
import { IPage } from '../models/page.model';
import { IBreadcrumbsProps } from '../models/breadcrumbs.model';

import { getNodes } from '../utils/get-nodes';

import MainLayout from '../layouts/main-layout';
import RecipeMeta from '../components/organisms/recipe-meta';
import RecipeDetails from '../components/organisms/recipe-details';
import RecipeVideo from '../components/organisms/recipe-video';
import RecipeSlider from '../components/organisms/recipe-slider';
import VlogSection from '../components/organisms/vlog-section';

interface IRecipePage {
    data: {
        recipe: IRecipe;
        promotedRecipes: IQueryAllResult<IRecipe>;
        lastThreeVideos: IQueryAllResult<IVideo>;
        pageBreadcrumbs: {
            breadcrumbs: IBreadcrumbsProps[];
        };
    };
}

const RecipePage: React.FC<IRecipePage> = ({ data }: IRecipePage) => {
    const { promotedRecipes, lastThreeVideos, recipe } = data;
    const promotedRecipesNodes = getNodes(promotedRecipes);
    const lastThreeVideosNodes = getNodes(lastThreeVideos);
    const { t } = useI18next();
    const meta: IMeta = {
        title: recipe.title,
        description: recipe.description,
        image: recipe.mainImage,
    };
    const page: IPage = {
        meta: meta,
    };

    return (
        <MainLayout
            page={page}
            className={grid}
            showRecipesSlider={false}
            includeHeaderPadding={true}
            showLocalizationBar={true}
            breadcrumbs={data.pageBreadcrumbs.breadcrumbs}
        >
            <div className={contentWrapper}>
                <div className={pageSection}>
                    <RecipeMeta recipe={recipe} />
                </div>
                <div className={pageSection}>
                    <RecipeDetails recipe={recipe} />
                </div>
                {recipe.relatedVideo && (
                    <div className={pageSection}>
                        <RecipeVideo recipeVideo={recipe.relatedVideo} recipeSteps={recipe.steps} />
                    </div>
                )}
                {recipe.relatedRecipes.length > 0 && (
                    <div className={pageSection}>
                        <RecipeSlider
                            showSummary={false}
                            slides={recipe.relatedRecipes}
                            titleText={t('recipe.page.slider.title')}
                            subtitleText={t('recipe.page.slider.subtitle')}
                        />
                    </div>
                )}
                {lastThreeVideosNodes.length > 0 && (
                    <div className={pageSection}>
                        <VlogSection
                            titleClassName={vlogHeader}
                            sliderClassName={vlogSlider}
                            title={t('recipe.page.vlog.title')}
                            videos={lastThreeVideosNodes}
                        />
                    </div>
                )}
                {promotedRecipesNodes.length > 0 && (
                    <div className={pageSection}>
                        <RecipeSlider
                            showSummary={true}
                            slides={promotedRecipesNodes}
                            titleText={t('recipe.page.promoted.title')}
                            subtitleText={t('recipe.page.promoted.subtitle')}
                        />
                    </div>
                )}
            </div>
        </MainLayout>
    );
};

export const query = graphql`
    query ($language: String!, $recipeId: Int!, $path: String) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }

        pageBreadcrumbs: sitePage(path: { eq: $path }) {
            breadcrumbs {
                label
                slug
            }
        }

        recipe: strapiRecipe(recipeId: { eq: $recipeId }, locale: { eq: $language }) {
            ...recipeFields
        }

        promotedRecipes: allStrapiRecipe(filter: { isPromoted: { eq: true }, locale: { eq: $language } }) {
            edges {
                node {
                    ...recipeCardFields
                }
            }
        }

        lastThreeVideos: allStrapiVideo(limit: 3, sort: { fields: published_at, order: DESC }, filter: { locale: { eq: $language } }) {
            edges {
                node {
                    ...baseVideoFields
                    slug
                }
            }
        }
    }
`;

export default RecipePage;
