import React from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import { getImage } from 'gatsby-plugin-image';

import {
    grid,
    ingredientsContainer,
    recipeContainer,
    imageContainer,
    description,
    header,
    noSlide,
    imageRatio,
} from './recipe-details.module.scss';

import { IRecipe } from '../../models/recipe.model';

import BlockHeader from '../molecules/block-header';
import RatioImage from '../atoms/ratio-image';
import RecipeIngredients from '../molecules/recipe-ingredients';
import AngleButton from '../atoms/angle-button';
import Slider from '../hoc/slider';
import Markdown from '../hoc/markdown';

interface IRecipeDetails {
    recipe: IRecipe;
}

const RecipeDetails: React.FC<IRecipeDetails> = ({ recipe }) => {
    const { ingredients, portionText, relatedProducts, stepsDescription } = recipe;
    const { t } = useI18next();

    if (
        (!ingredients || ingredients.length === 0) &&
        !portionText &&
        (!relatedProducts || relatedProducts.length === 0)
    ) {
        return null;
    }

    return (
        <div className={grid}>
            <div className={ingredientsContainer}>
                {ingredients && ingredients.length > 0 && (
                    <>
                        <BlockHeader
                            mobileHideSubtitle={false}
                            className={header}
                            titleText={t('recipe.page.details.ingredients')}
                            subtitleText={portionText}
                            subtitleUppercase={false}
                        />
                        <RecipeIngredients ingredients={ingredients} />
                    </>
                )}
            </div>
            <div className={recipeContainer}>
                {stepsDescription && (
                    <>
                        <BlockHeader
                            mobileHideSubtitle={false}
                            className={header}
                            titleText={t('recipe.page.details.recipe')}
                            subtitleText={portionText}
                        />
                        {stepsDescription && (
                            <Markdown className={description}>{stepsDescription}</Markdown>
                        )}
                    </>
                )}
            </div>
            <div className={imageContainer}>
                {relatedProducts && relatedProducts.length > 0 && (
                    <Slider
                        ButtonComponent={AngleButton}
                        showNav="none"
                        noSliderItemClass={noSlide}
                        breakpoints={{ 0: { slidesPerView: 1 } }}
                        sliderProps={{
                            pagination: {
                                clickable: true,
                            },
                        }}
                    >
                        {relatedProducts.map((item) => {
                            return (
                                <RatioImage
                                    key={`related_product_${item.id}`}
                                    ratioClass={imageRatio}
                                    image={item.media?.localFile && getImage(item.media?.localFile)}
                                    alt={item.media?.alternativeText}
                                    objectFit="contain"
                                />
                            );
                        })}
                    </Slider>
                )}
            </div>
        </div>
    );
};

export default RecipeDetails;
