import { polishPlurals as basePolishPlurals } from 'polish-plurals';

export interface INounVariants {
    one: string;
    two: string;
    five: string;
    half: string;
}

export const nounVariants: (keyof INounVariants)[] = ['one', 'two', 'five', 'half'];

export default function polishPlurals(quantity: number, nounVariants: INounVariants) {
    const { one, two, five, half } = nounVariants;
    const absoluteQuantity = Math.abs(quantity);

    if (absoluteQuantity > 0 && absoluteQuantity < 1) {
        return half;
    }

    return basePolishPlurals(one, two, five, quantity);
}
