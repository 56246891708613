// extracted by mini-css-extract-plugin
export var backgroundAnimation = "recipe-details-module--backgroundAnimation--349a2";
export var description = "recipe-details-module--description--d80da";
export var errorBlink = "recipe-details-module--error-blink--14050";
export var grid = "recipe-details-module--grid--27d75";
export var header = "recipe-details-module--header--81716";
export var imageContainer = "recipe-details-module--image-container--7d204";
export var imageRatio = "recipe-details-module--image-ratio--16d52";
export var ingredientsContainer = "recipe-details-module--ingredients-container--c06a7";
export var noSlide = "recipe-details-module--no-slide--78907";
export var recipeContainer = "recipe-details-module--recipe-container--70c69";