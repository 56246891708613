import React from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { container, box, number, step, stepName, description } from './recipe-steps.module.scss';
import { IRecipeSteps } from '../../models/recipe.model';

interface IRecipeStepsBox {
    className?: string;
    recipeSteps: IRecipeSteps[];
}

const RecipeSteps: React.FC<IRecipeStepsBox> = ({ className = '', recipeSteps }) => {
    const { t } = useI18next();
    return (
        <div className={`${container} ${className}`}>
            {recipeSteps.map((item, index) => {
                return (
                    <div className={box} key={`step_${item.id}`}>
                        <span className={number}>{index + 1}</span>
                        <span className={step}>{t('recipe.page.video.step')}</span>
                        <span className={stepName}>{item.title}</span>
                        <p className={description}>{item.description}</p>
                    </div>
                );
            })}
        </div>
    );
};

export default RecipeSteps;
