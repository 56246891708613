// extracted by mini-css-extract-plugin
export var animatedTextHover = "video-card-module--animated-text-hover--405a2";
export var backgroundAnimation = "video-card-module--backgroundAnimation--04b27";
export var errorBlink = "video-card-module--error-blink--34e68";
export var videoCard = "video-card-module--video-card--24a4c";
export var videoDate = "video-card-module--video-date--8390f";
export var videoDescription = "video-card-module--video-description--61f08";
export var videoDuration = "video-card-module--video-duration--da407";
export var videoFooter = "video-card-module--video-footer--89f43";
export var videoHeader = "video-card-module--video-header--17a3b";
export var videoLink = "video-card-module--video-link--61380";
export var videoPlayer = "video-card-module--video-player--5b23e";
export var videoRatio = "video-card-module--video-ratio--0e683";
export var videoTitle = "video-card-module--video-title--51009";