import React from 'react';

import { categoryIcon, categoryLabel, categoryText, iconFrame } from './category-label.module.scss';

import { IRecipeCategory } from '../../models/recipe-category.model';

import prependStrapiUrl from '../../utils/prepend-strapi-url';

interface ICategoryLabel {
    className?: string;
    category: IRecipeCategory;
}

const CategoryLabel: React.FC<ICategoryLabel> = ({ category, className = '' }) => {
    if (!category?.icon) {
        return null;
    }

    return (
        <div className={`${categoryLabel} ${className}`}>
            <div className={iconFrame}>
                <img
                    className={categoryIcon}
                    src={prependStrapiUrl(category.icon.url)}
                    alt={category.icon.alternativeText}
                />
            </div>
            <span className={categoryText}>{category.title}</span>
        </div>
    );
};

export default CategoryLabel;
