import React from 'react';
import { cell, table } from './recipe-ingredients.module.scss';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { IRecipeIngredient } from '../../models/recipe.model';
import { IQuantity } from '../../models/quantity.model';
import polishPlurals, { INounVariants, nounVariants } from '../../utils/polish-plurals';

interface IRecipeIngredients {
    ingredients: IRecipeIngredient[];
}

const RecipeIngredients: React.FC<IRecipeIngredients> = ({ ingredients }) => {
    const { t, language } = useI18next();
    return (
        <div className={table}>
            {ingredients.map((item) => {
                return (
                    <React.Fragment key={`recipe_ingredient_${item.quantity.id}`}>
                        <div className={cell}>{quantityToString(item.quantity, t, language)}</div>
                        <div key={item.id} className={cell}>
                            {item.ingridientName}
                        </div>
                    </React.Fragment>
                );
            })}
        </div>
    );
};

function quantityToString(
    quantity: IQuantity,
    t: ReturnType<typeof useI18next>['t'],
    language: string
) {
    if (!quantity.value) {
        return '';
    }

    let unit = quantity.unit || '';

    if (translatableQuantityUnits.includes(unit)) {
        unit = polishPlurals(quantity.value, getPluralConfig(unit, t));
    }

    const formattedValue = new Intl.NumberFormat(language).format(quantity.value);

    return `${formattedValue} ${unit}`;
}

function getPluralConfig(unit: string, t: ReturnType<typeof useI18next>['t']) {
    return Object.fromEntries(
        nounVariants.map<[keyof INounVariants, string]>((variant) => [
            variant,
            t(`quantity.${unit}.${variant}`),
        ])
    );
}

// these units correspond with keys in translation.json
const translatableQuantityUnits = ['glass', 'spoon', 'littleSpoon', 'parts'];

export default RecipeIngredients;
