import React from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { videoSliderWrapper, textContainer, sliderContainer } from './vlog-section.module.scss';

import pagesContext from '../../config/pages-context';

import { IVideo } from '../../models/video.model';

import Title from '../atoms/title';
import VideoSlider from '../molecules/video-slider';
import ListingLink from '../atoms/listing-link';

interface IVlogSection {
    className?: string;
    titleClassName?: string;
    sliderClassName?: string;
    title: string;
    videos: IVideo[];
    videoCardType?: 'simple' | 'full';
}

const VlogSection: React.FC<IVlogSection> = ({
    className = '',
    titleClassName = '',
    sliderClassName = '',
    title,
    videos,
}) => {
    const { t, language, defaultLanguage } = useI18next();
    const context = language === defaultLanguage ? pagesContext.videoListingPl : pagesContext.videoListingEn;
    const { slug } = context;

    return (
        <div className={`${videoSliderWrapper} ${className}`}>
            <Title isUppercase={true} className={`${textContainer} ${titleClassName}`}>
                {title}
            </Title>
            <VideoSlider className={`${sliderContainer} ${sliderClassName}`} data={videos} />
            <ListingLink to={language === defaultLanguage ? `/${slug}/` : `/${language}/${slug}/`} text={t('vlog.section.link')} />
        </div>
    );
};

export default VlogSection;
