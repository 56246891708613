import React from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';

import { container, header, steps, videoRatio, videoPlayer } from './recipe-video.module.scss';

import pagesContext from '../../config/pages-context';

import { IRecipeSteps, IRecipeVideo } from '../../models/recipe.model';

import prependStrapiUrl from '../../utils/prepend-strapi-url';

import BlockHeader from '../molecules/block-header';
import RecipeSteps from '../molecules/recipe-steps';
import VideoPlayer from '../atoms/video-player';
import ListingLink from '../atoms/listing-link';

interface IRecipeVideoBox {
    recipeVideo: IRecipeVideo;
    recipeSteps?: IRecipeSteps[];
}

const RecipeVideo: React.FC<IRecipeVideoBox> = ({ recipeVideo, recipeSteps }) => {
    const { t, defaultLanguage, language } = useI18next();
    const context = defaultLanguage === language ? pagesContext.videoListingPl : pagesContext.videoListingEn;
    const { slug } = context;

    const videoUrl = prependStrapiUrl(recipeVideo.videoFile.url);
    const videoThumbnailSrc = prependStrapiUrl(recipeVideo.videoThumbnail.url);

    return (
        <div className={container}>
            <BlockHeader
                mobileHideSubtitle={false}
                className={header}
                titleText={t('recipe.page.video.title')}
                subtitleText={t('recipe.page.video.subtitle')}
            />
            {recipeSteps && recipeSteps.length > 0 && (
                <RecipeSteps className={steps} recipeSteps={recipeSteps} />
            )}
            <VideoPlayer
                url={videoUrl}
                light={videoThumbnailSrc}
                ratioClassName={videoRatio}
                className={videoPlayer}
            />
            <ListingLink to={slug} text={t('recipe.page.video.link')} />
        </div>
    );
};

export default RecipeVideo;
