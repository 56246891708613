import React from 'react';
import { useI18next } from '../../../plugins/gatsby-plugin-ap-i18next/src/useI18next';
import Link from '../../../plugins/gatsby-plugin-ap-i18next/src/link';

import {
    videoCard,
    videoPlayer,
    videoRatio,
    videoDate,
    videoDescription,
    videoDuration,
    videoFooter,
    videoHeader,
    videoLink,
    animatedTextHover,
    videoTitle,
} from './video-card.module.scss';
import { IVideo } from '../../models/video.model';
import AngleIcon from '../../assets/images/svg/angle.svg';
import prependStrapiUrl from '../../utils/prepend-strapi-url';

import VideoPlayer from '../atoms/video-player';

interface IVideoCard {
    readonly data: IVideo;
}

const VideoCard: React.FC<IVideoCard> = ({ data }) => {
    const { title, description, videoFile, videoThumbnail, published_at, slug } = data;
    const { t } = useI18next();
    const videoUrl = prependStrapiUrl(videoFile.url);
    const videoThumbnailSrc = prependStrapiUrl(videoThumbnail.url);

    return (
        <article className={videoCard}>
            <Link to={slug}>
                <VideoPlayer
                    url={videoUrl}
                    ratioClassName={videoRatio}
                    className={videoPlayer}
                    light={videoThumbnailSrc}
                />
            </Link>
            <header className={videoHeader}>
                <h2 className={videoTitle}>{title}</h2>
                <span className={videoDuration}>30 min</span>
            </header>
            <p className={videoDescription}>{description}</p>
            <footer className={videoFooter}>
                <span className={videoDate}>
                    {new Date(published_at).toLocaleString().split(',')[0]}
                </span>
                <Link className={videoLink} to={slug}>
                    <span className={animatedTextHover}>{t('video.card.link')}</span>
                    <AngleIcon />
                </Link>
            </footer>
        </article>
    );
};

export default VideoCard;
